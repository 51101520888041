import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';
import VueRouter, { RouteConfig } from 'vue-router';
import { Store as VuexStore } from 'vuex';

import { Store } from '@/store';
import { ControlMode } from '@/enums/ControlMode';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Start',
    component: () => import(/* webpackChunkName: "start" */ '../views/Start.vue'),
  },
  {
    path: '/authorization',
    name: 'HandleAuthorization',
    component: () => import(/* webpackChunkName: "handleAuthorization" */ '../views/HandleAuthorization.vue'),
  },
  {
    path: '/building',
    name: 'AreaSelector',
    component: () => import(/* webpackChunkName: "areaSelector" */ '../views/AreaSelector.vue'),
  },
  {
    path: '/area/rooms',
    name: 'RoomSelector',
    component: () => import(/* webpackChunkName: "roomSelector" */ '../views/RoomSelector.vue'),
  },
  {
    path: '/area/room/control',
    name: 'RoomControl',
    component: () => import(/* webpackChunkName: "roomControl" */ '../views/RoomControl.vue'),
  },
  {
    path: '/area/scenes',
    name: 'ScenesSelector',
    component: () => import(/* webpackChunkName: "scenesSelector" */ '../views/ScenesSelector.vue'),
  },
  {
    path: '/area/scene-shortcut/name',
    name: 'SceneShortcutName',
    component: () => import(/* webpackChunkName: "sceneShortcutName" */ '../views/SceneShortcutName.vue'),
  },
  {
    path: '/area/control',
    name: 'AreaControl',
    component: () => import(/* webpackChunkName: "areaControl" */ '../views/AreaControl.vue'),
  },
];

export function createRouter(store: VuexStore<Store>): VueRouter {
  Vue.use(VueRouter);

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });

  router.beforeEach(
    (to, _, next) => {
      if (
        to.name != 'Start'
        && to.name != 'HandleAuthorization'
        && store.state.token == undefined
      ) {
        next({ name: 'Start', });
        return;
      }
      if (
        to.name != 'RoomControl'
        && store.state.controlMode == ControlMode.ROOM
        && store.state.selectedRoomId != undefined
      ) {
        next({ name: 'RoomControl', });
        return;
      }
      if (
        to.name != 'RoomSelector'
        && store.state.selectedBuildingId != undefined
        && store.state.selectedAreaId != undefined
        && store.state.controlMode == ControlMode.ROOM
        && store.state.selectedRoomId == undefined
      ) {
        next({ name: 'RoomSelector', });
        return;
      }
      if (
        to.name != 'ScenesSelector'
        && store.state.selectedBuildingId != undefined
        && store.state.selectedAreaId != undefined
        && store.state.controlMode == ControlMode.AREA
        && isEmpty(store.state.sceneShortcuts)
        && store.state.sceneShortcutDraft == undefined
      ) {
        next({ name: 'ScenesSelector', });
        return;
      }
      if (
        to.name != 'SceneShortcutName'
        && store.state.sceneShortcutDraft != undefined
      ) {
        next({ name: 'SceneShortcutName', });
        return;
      }
      if (
        to.name != 'ScenesSelector'
        && to.name != 'SceneShortcutName'
        && to.name != 'AreaControl'
        && store.state.controlMode == ControlMode.AREA
        && !isEmpty(store.state.sceneShortcuts)
      ) {
        next({ name: 'AreaControl', });
        return;
      }
      if (
        to.name != 'AreaSelector'
          && store.state.token != undefined
          && (
            store.state.selectedBuildingId == undefined
            || store.state.selectedAreaId == undefined
          )
          && store.state.selectedRoomId == undefined
        ) {
        next({ name: 'AreaSelector', });
        return;
      }
      next();
    },
  );

  return router;
}
