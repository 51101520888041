import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueApollo from 'vue-apollo';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import App from '@/App.vue';
import { createRouter } from '@/router';
import store from '@/store';

import { createApolloClient } from '@/apollo/ApolloClient';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueApollo);

Vue.config.productionTip = false;

const router = createRouter(store);

const apolloClient = createApolloClient(store, router);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app');
