import { ApolloClient } from 'apollo-client';
import { ApolloLink, concat } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { Store as VuexStore } from 'vuex';

import { Store } from '@/store';
import { rotateToken, tokenIsAboutToExpire } from '@/helpers/AccessTokenManager';
import introspectionQueryResultData from '@/possibleTypes.json';
import VueRouter from 'vue-router';

export interface GraphQLRequestContext {
  headers: {
    Authorization?: string;
  };
}

function getHTTPLink(
  uri: string,
  store: VuexStore<Store>,
): ApolloLink {
  const httpLink = createHttpLink({
    uri,
  });
  const middleware = setContext(
    () => new Promise<GraphQLRequestContext>(
      (resolve, reject) => {
        const token = store.state.token;
        if (token == undefined) {
          reject(new Error('Token is not set'));
          return;
        }
        if (tokenIsAboutToExpire(token.expiresAt)) {
          rotateToken(token.refreshToken)
            .then((rotatedToken) => {
              store.commit('setToken', rotatedToken);
              resolve({
                headers: {
                  Authorization: `${store.state.token?.tokenType} ${store.state.token?.accessToken}`,
                },
              });
            });
          return;
        }
        resolve({
          headers: {
            Authorization: `${store.state.token?.tokenType} ${store.state.token?.accessToken}`,
          },
        });
      }
    ),
  );
  return concat(middleware, httpLink);
}

function getErrorLink(
  store: VuexStore<Store>,
  router: VueRouter,
): ApolloLink {
  return onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      store.commit('dropState');
      router.push({ name: 'Start', });
      return;
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });
}

export function createApolloClient(
  store: VuexStore<Store>,
  router: VueRouter,
): ApolloClient<unknown> {
  const uri = process.env.VUE_APP_WIZ_PRO_DASHBOARD_API;
  if (uri == undefined) {
    throw new Error('Pro Dashboard API URL is required but not provided');
  }
  const httpLink = getHTTPLink(uri, store);
  const errorLink = getErrorLink(store, router);
  const link = ApolloLink.from([errorLink, httpLink]);

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });
  const cache = new InMemoryCache({
    fragmentMatcher,
  });

  const apolloClient = new ApolloClient({
    link,
    cache,
  });

  return apolloClient;
}
