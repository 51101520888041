import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";
import concat from 'lodash/concat';
import filter from 'lodash/filter';

import { Token } from '@/helpers/AccessTokenManager';
import { ControlMode } from '@/enums/ControlMode';
import { SceneShortcut } from '@/interfaces/scenes/SceneShortcut';
import { generateKey } from '@/helpers/StringGenerator';

Vue.use(Vuex);

export interface Store {
  codeVerifier?: string;
  loadingProcesses: string[];
  token: Token | undefined;
  selectedBuildingId?: string;
  selectedAreaId?: string;
  selectedRoomId?: string;
  controlMode?: ControlMode;
  sceneShortcuts: SceneShortcut[];
  sceneShortcutDraft: string[] | undefined;
}

const ls = new SecureLS({ isCompression: false });

export default new Vuex.Store<Store>({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => {
          try {
            return ls.get(key);
          } catch (e) {
            return {};
          }
        },
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    loadingProcesses: [],
    token: undefined,
    sceneShortcuts: [],
    sceneShortcutDraft: undefined,
  },
  mutations: {
    dropState(state) {
      state.codeVerifier = undefined;
      state.loadingProcesses = [];
      state.token = undefined;
      state.selectedBuildingId = undefined;
      state.selectedAreaId = undefined;
      state.selectedRoomId = undefined;
      state.controlMode = undefined;
      state.sceneShortcuts = [];
      state.sceneShortcutDraft = undefined;
    },
    resetToAreas(state) {
      state.selectedAreaId = undefined;
      state.selectedRoomId = undefined;
      state.controlMode = undefined;
      state.sceneShortcutDraft = undefined;
      state.sceneShortcuts = [];
    },
    setCodeVerifier(state, codeVerifier: string) {
      state.codeVerifier = codeVerifier;
    },
    removeCodeVerifier(state) {
      state.codeVerifier = undefined;
    },
    addLoadingProcess(state, processId: string) {
      state.loadingProcesses = concat(state.loadingProcesses, processId);
    },
    stopLoadingProcess(state, processId: string) {
      state.loadingProcesses = filter(
        state.loadingProcesses,
        (runningProcessId) => runningProcessId != processId,
      );
    },
    setToken(state, token: Token) {
      state.token = token;
    },
    setSelectedBuildingId(state, selectedBuildingId: string) {
      state.selectedBuildingId = selectedBuildingId;
    },
    setSelectedAreaId(state, selectedAreaId: string) {
      state.selectedAreaId = selectedAreaId;
    },
    resetSelectedArea(state) {
      state.selectedAreaId = undefined;
    },
    setSelectedRoomId(state, selectedRoomId: string) {
      state.selectedRoomId = selectedRoomId;
    },
    setControlMode(state, controlMode: ControlMode) {
      state.controlMode = controlMode;
    },
    resetControlMode(state) {
      state.controlMode = undefined;
    },
    setSceneShortcutDraft(state, sceneIds: string[]) {
      state.sceneShortcutDraft = sceneIds;
    },
    resetSceneShortcutDraft(state) {
      state.sceneShortcutDraft = undefined;
    },
    saveSceneShortcutDraft(state, name: string) {
      if (state.sceneShortcutDraft == undefined) {
        return;
      }
      const sceneShortcut: SceneShortcut = {
        id: generateKey(),
        name,
        sceneIds: state.sceneShortcutDraft,
      };
      state.sceneShortcuts = concat(state.sceneShortcuts, sceneShortcut);
      state.sceneShortcutDraft = undefined;
    },
    removeSceneShortcut(state, sceneShortcut: SceneShortcut) {
      state.sceneShortcuts = filter(
        state.sceneShortcuts,
        (saveShortcut) => saveShortcut.id != sceneShortcut.id,
      );
    },
  },
  actions: {
  },
  modules: {
  },
});
