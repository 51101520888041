import * as axios from 'axios';

export interface Token {
  accessToken: string;
  tokenType: string;
  refreshToken: string;
  expiresAt: string;
}

export async function getAccessToken(
  clientId: string,
  authorizationCode: string,
  codeVerifier: string,
): Promise<Token> {
  const oauthUrl = process.env.VUE_APP_WIZ_PRO_DASHBOARD_OAUTH;
  if (oauthUrl == undefined) {
    throw new Error('Oauth endpoint is required but not found');
  }
  const response = await axios
    .default
    .post<Token>(
      oauthUrl,
      {
        grantType: 'authorizationCode',
        authorizationCode,
        clientId,
        codeVerifier,
      },
    );
  return response.data;
}

export async function rotateToken(
  refreshToken: string,
): Promise<Token> {
  const oauthUrl = process.env.VUE_APP_WIZ_PRO_DASHBOARD_OAUTH;
  if (oauthUrl == undefined) {
    throw new Error('Oauth endpoint is required but not found');
  }
  const response = await axios
    .default
    .post<Token>(
      oauthUrl,
      {
        grantType: 'refreshToken',
        refreshToken,
      },
    );
  return response.data;
}

export function tokenIsAboutToExpire(expiresAt: string): boolean {
  const now = (new Date());
  now.setHours(now.getHours() - 1);
  const expiryDate = new Date(expiresAt);
  return now > expiryDate;
}
