




















import Vue from 'vue';
import { Route } from 'vue-router';
import isEmpty from 'lodash/isEmpty';

import BulbSpinner from '@/components/spinners/BulbSpinner.vue';

export default Vue.extend({
  components: {
    BulbSpinner,
  },
  data(): {
    enterTransitionClass: string;
    leaveTransitionClass: string;
  } {
    return {
      enterTransitionClass: 'slide-initial',
      leaveTransitionClass: 'slide-initial',
    };
  },
  watch: {
    '$route'(to: Route, from: Route) {
      if (
        from.name == undefined
        || from.name === to.name
      ) {
        this.disableAnimation();
        return;
      }
      if (from.name == 'AreaSelector') {
        if (to.name == 'Start') {
          this.setReverseAnimation();
        } else {
          this.setStraightAnimation();
        }
        return;
      }
      if (from.name == 'RoomSelector') {
        if (to.name == 'AreaSelector') {
          this.setReverseAnimation();
        } else {
          this.setStraightAnimation();
        }
        return;
      }
      if (from.name == 'ScenesSelector') {
        if (to.name == 'AreaSelector') {
          this.setReverseAnimation();
        } else {
          this.setStraightAnimation();
        }
        return;
      }
      if (from.name == 'SceneShortcutName') {
        if (to.name == 'ScenesSelector') {
          this.setReverseAnimation();
        } else {
          this.setStraightAnimation();
        }
        return;
      }
      if (from.name == 'AreaControl') {
        this.setReverseAnimation();
        return;
      }
      if (to.name == 'RoomControl') {
        this.setStraightAnimation();
        return;
      }
      this.disableAnimation();
    },
  },
  computed: {
    loading(): boolean {
      return !isEmpty(this.$store.state.loadingProcesses);
    },
  },
  methods: {
    disableAnimation() {
      this.enterTransitionClass = 'slide-initial';
      this.leaveTransitionClass = 'slide-initial';
    },
    setStraightAnimation() {
      this.enterTransitionClass = 'slide-straight';
      this.leaveTransitionClass = 'slide-reverse';
    },
    setReverseAnimation() {
      this.enterTransitionClass = 'slide-reverse';
      this.leaveTransitionClass = 'slide-straight';
    },
  },
});
